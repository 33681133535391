import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { LngLatLike, Map } from 'maplibre-gl';
import { GetServerSideProps } from 'next';
import { toast } from 'sonner';
import { useRouter } from 'next/router';
import { TightLayout } from '@/components/layout/Layout';
import { MapContextProvider, useMap } from '@/hooks/useMap';
import * as MapFeaturesLayer from '@/components/ui/map/layers/MapFeatures';
import * as MapFeaturesData from '@/components/ui/map/sources/MapFeaturesData';
import { IMapOptions } from '@/types/map';
import { Props as MapProps } from '@/hooks/useMap';
import * as Toast from '@/components/ui/toast/Toast';

interface Props {
  country: string;
}

const INITIAL_LOCATIONS = [
  { lat: 34.04801, lon: -118.252586, zoom: 16, name: 'Los Angeles' }, // LA
  { lat: 37.78513, lon: -122.402975, zoom: 16, name: 'San Francisco' }, // SF
  { lat: 33.44566, lon: -112.072961, zoom: 16, name: 'Phoenix' }, // PHX
  { lat: 41.884178, lon: -87.627867, zoom: 15, name: 'Chicago' }, // CHI
];

function MapPage({ country }: Props) {
  const [screenWidth, setScreenWidth] = useState<number | undefined>();
  const options: IMapOptions = { maxZoom: 18 };
  const router = useRouter();
  const initialLocation = useMemo(
    () =>
      INITIAL_LOCATIONS[Math.floor(Math.random() * INITIAL_LOCATIONS.length)],
    [],
  );

  // Only init map at rand loc if no coordates params are set.
  const shouldInitWithRandomLocation = !router.query.center;

  const mapContextProps: MapProps = {
    country,
    screenWidth: screenWidth, // this defines what to show on the map by default
    options,
    ...(shouldInitWithRandomLocation ? initialLocation : undefined),
  };

  useEffect(() => {
    if (window) {
      setScreenWidth(window.innerWidth);
    }
  }, []);

  return (
    <TightLayout>
      {screenWidth && (
        <MapContextProvider {...mapContextProps}>
          <MapContent
            shouldInitWithRandomLocation={shouldInitWithRandomLocation}
            initialLocation={initialLocation}
          />
        </MapContextProvider>
      )}
    </TightLayout>
  );
}

function MapContent({
  shouldInitWithRandomLocation,
  initialLocation,
}: {
  shouldInitWithRandomLocation: boolean;
  initialLocation: LngLatLike & { name: string };
}) {
  const mapMounted = useRef(false);
  const { map, setAreLayersReady, mapContainerRef } = useMap();
  const [toastId, setToastId] = useState<string | number>();

  const initializeData = useCallback(
    async (
      map: Map,
      setAreLayersReady: React.Dispatch<React.SetStateAction<boolean>>,
    ) => {
      setAreLayersReady(false);

      // Add source and layer for points
      await MapFeaturesData.addSource(map);
      MapFeaturesLayer.addLayer(map);
      setAreLayersReady(true);

      // Only show intro toast if we are starting the map at a random location.
      if (shouldInitWithRandomLocation) {
        const toastId = toast.custom(
          t => (
            <Toast.Default
              title={`${initialLocation.name} Data Sample`}
              description="Speed limits, turn restrictions, traffic lights, stop signs and exit signs detected by Hivemapper Map AI."
              buttons={[
                {
                  label: 'Dismiss',
                  onClick: () => toast.dismiss(t),
                  variant: 'secondary',
                  size: 'fw',
                },
                {
                  label: 'Learn More',
                  onClick: () => {
                    window.open(
                      '/product/map-features-api',
                      '_blank',
                      'noopener,noreferrer',
                    );
                    toast.dismiss(t);
                  },
                  variant: 'default',
                  size: 'fw',
                },
              ]}
            />
          ),
          { duration: Infinity, className: 'right-10 -bottom-4' },
        );
        setToastId(toastId);
      }
    },
    [initialLocation.name, shouldInitWithRandomLocation],
  );

  useEffect(() => {
    if (map && !mapMounted.current) {
      initializeData(map, setAreLayersReady);
      mapMounted.current = true;
    }
  }, [map, initializeData, setAreLayersReady]);

  useEffect(() => {
    return () => {
      if (toastId) {
        toast.dismiss(toastId);
      }
    };
  }, [toastId]);

  return (
    <div ref={mapContainerRef} className="absolute inset-0 flex size-full" />
  );
}

export default MapPage;

export const getServerSideProps: GetServerSideProps = async ({ req }) => {
  const country = req.headers['x-country'] || 'unknown';
  return {
    props: {
      country,
    },
  };
};
