import { Map } from 'maplibre-gl';

export const SOURCE_ID = 'hm-mapFeaturesData';
const TILES_URL =
  'https://map-features-proxy.hivemapper.com/map-features/{z}/{x}/{y}.mvt' as const;

export async function addSource(map: Map) {
  if (map && !map.getSource(SOURCE_ID)) {
    try {
      map.addSource(SOURCE_ID, {
        type: 'vector',
        tiles: [TILES_URL],
        maxzoom: 15,
        minzoom: 15,
      });
    } catch (err) {
      console.log(err);
    }
  }
}
