import { Map } from 'maplibre-gl';
import { getFirstLabelId } from '@/utils/map';
import { SOURCE_ID } from '@/components/ui/map/sources/MapFeaturesData';
import {
  exitSignIcon,
  getSchoolSpeedLimitIcon,
  getSpeedLimitIcon,
  noLeftTurnIcon,
  noLeftTurnNoUTurnIcon,
  noRightTurnIcon,
  noThroughIcon,
  noTurnsIcon,
  noUTurnIcon,
  speedLimitIcon,
  stopSignIcon,
  streetLightIcon,
  turnRestrictionIcon,
} from '@/components/ui/icons/map/mapFeatures';

interface ILayer {
  name: string;
  svg: string;
}

const usSchoolSpeedLimits = [5, 8, 10, 12, 15, 20, 25, 30, 35];
const usSpeedLimits = [
  5, 8, 10, 12, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90,
  95,
];
const LAYER_NAME = 'hm-map-features' as const;

function isTurnRuleIconKey(key: string): key is keyof TurnRuleIcons {
  return key in turnRuleIcons;
}

export function addLayer(map: Map) {
  const mapFeatureTypes: ILayer[] = [
    {
      name: 'label-highway-sign',
      svg: exitSignIcon,
    },
    {
      name: 'label-speed-sign',
      svg: speedLimitIcon,
    },
    {
      name: 'label-stop-sign',
      svg: stopSignIcon,
    },
    {
      name: 'label-traffic-light',
      svg: streetLightIcon,
    },
    {
      name: 'label-turn-rules-sign',
      svg: turnRestrictionIcon,
    },
  ];

  const loadSvgImage = async (svg: string): Promise<HTMLImageElement> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svg);
      img.onload = () => resolve(img);
      img.onerror = reject;
    });
  };

  const addImageToMap = async (name: string, svg: string) => {
    const img = await loadSvgImage(svg);
    map.addImage(name, img);
  };

  // Add corresponding icon for each map feature type.
  mapFeatureTypes.forEach(async type => {
    await addImageToMap(`${type.name}-img`, type.svg);

    if (type.name === 'label-turn-rules-sign') {
      for (const rule in turnRuleIcons) {
        if (isTurnRuleIconKey(rule)) {
          await addImageToMap(rule, turnRuleIcons[rule]);
        }
      }
    }

    if (type.name === 'label-speed-sign') {
      // Add regular speed limit icons.
      usSpeedLimits.forEach(speedLimit => {
        addImageToMap(
          `speed-limit-${speedLimit}`,
          getSpeedLimitIcon(speedLimit),
        );
      });

      // Add school speed limit icons.
      usSchoolSpeedLimits.forEach(speedLimit => {
        addImageToMap(
          `school-speed-limit-${speedLimit}`,
          getSchoolSpeedLimitIcon(speedLimit),
        );
      });
    }
  });

  if (!map.getLayer(LAYER_NAME)) {
    map.addLayer(
      {
        id: LAYER_NAME,
        source: SOURCE_ID,
        type: 'symbol',
        'source-layer': 'map-features',
        layout: {
          // Sort icons by feature type. Lower value will be hidden last during collision.
          // See https://docs.mapbox.com/style-spec/reference/layers#layout-symbol-symbol-sort-key
          'symbol-sort-key': [
            'case',
            ['==', ['get', 'label'], 'turn-rules-sign'],
            0,
            ['==', ['get', 'label'], 'speed-sign'],
            1,
            ['==', ['get', 'label'], 'traffic-light'],
            2,
            ['==', ['get', 'label'], 'stop-sign'],
            3,
            ['==', ['get', 'label'], 'highway-sign'],
            4,
            5,
          ],
          'icon-image': [
            'case',
            ['==', ['get', 'label'], 'highway-sign'],
            'label-highway-sign-img',
            ['==', ['get', 'label'], 'stop-sign'],
            'label-stop-sign-img',
            ['==', ['get', 'label'], 'traffic-light'],
            'label-traffic-light-img',
            // Speed sign icons.
            ['==', ['get', 'label'], 'speed-sign'],
            [
              'case',
              ['==', ['get', 'speedLimit'], '5'],
              [
                'case',
                ['==', ['get', 'speedType'], 'school'],
                'school-speed-limit-5',
                'speed-limit-5',
              ],
              ['==', ['get', 'speedLimit'], '8'],
              [
                'case',
                ['==', ['get', 'speedType'], 'school'],
                'school-speed-limit-8',
                'speed-limit-8',
              ],
              ['==', ['get', 'speedLimit'], '10'],
              [
                'case',
                ['==', ['get', 'speedType'], 'school'],
                'school-speed-limit-10',
                'speed-limit-10',
              ],
              ['==', ['get', 'speedLimit'], '12'],
              [
                'case',
                ['==', ['get', 'speedType'], 'school'],
                'school-speed-limit-12',
                'speed-limit-12',
              ],
              ['==', ['get', 'speedLimit'], '15'],
              [
                'case',
                ['==', ['get', 'speedType'], 'school'],
                'school-speed-limit-15',
                'speed-limit-15',
              ],
              ['==', ['get', 'speedLimit'], '20'],
              [
                'case',
                ['==', ['get', 'speedType'], 'school'],
                'school-speed-limit-20',
                'speed-limit-20',
              ],
              ['==', ['get', 'speedLimit'], '25'],
              [
                'case',
                ['==', ['get', 'speedType'], 'school'],
                'school-speed-limit-25',
                'speed-limit-25',
              ],
              ['==', ['get', 'speedLimit'], '30'],
              [
                'case',
                ['==', ['get', 'speedType'], 'school'],
                'school-speed-limit-30',
                'speed-limit-30',
              ],
              ['==', ['get', 'speedLimit'], '35'],
              [
                'case',
                ['==', ['get', 'speedType'], 'school'],
                'school-speed-limit-35',
                'speed-limit-35',
              ],
              ['==', ['get', 'speedLimit'], '40'],
              'speed-limit-40',
              ['==', ['get', 'speedLimit'], '45'],
              'speed-limit-45',
              ['==', ['get', 'speedLimit'], '50'],
              'speed-limit-50',
              ['==', ['get', 'speedLimit'], '55'],
              'speed-limit-55',
              ['==', ['get', 'speedLimit'], '60'],
              'speed-limit-60',
              ['==', ['get', 'speedLimit'], '65'],
              'speed-limit-65',
              ['==', ['get', 'speedLimit'], '70'],
              'speed-limit-70',
              ['==', ['get', 'speedLimit'], '75'],
              'speed-limit-75',
              ['==', ['get', 'speedLimit'], '80'],
              'speed-limit-80',
              ['==', ['get', 'speedLimit'], '85'],
              'speed-limit-85',
              ['==', ['get', 'speedLimit'], '90'],
              'speed-limit-90',
              ['==', ['get', 'speedLimit'], '95'],
              'speed-limit-95',
              'speed-sign-img',
            ],
            // Turn rule icons.
            ['==', ['get', 'label'], 'turn-rules-sign'],
            [
              'case',
              ['==', ['get', 'turnRules'], 'no left turn'],
              'no left turn',
              ['==', ['get', 'turnRules'], 'no u-turn no left turn'],
              'no u-turn no left turn',
              ['==', ['get', 'turnRules'], 'no turns'],
              'no turns',
              ['==', ['get', 'turnRules'], 'no straight through'],
              'no straight through',
              ['==', ['get', 'turnRules'], 'no right turn'],
              'no right turn',
              ['==', ['get', 'turnRules'], 'no u-turn'],
              'no u-turn',
              ['==', ['get', 'turnRules'], 'noLeftTurn'],
              'noLeftTurn',
              ['==', ['get', 'turnRules'], 'noRightTurn'],
              'noRightTurn',
              ['==', ['get', 'turnRules'], 'noStraightThrough'],
              'noStraightThrough',
              ['==', ['get', 'turnRules'], 'noTurns'],
              'noTurns',
              ['==', ['get', 'turnRules'], 'noUTurn'],
              'noUTurn',
              ['==', ['get', 'turnRules'], 'noUTurnNoLeftTurn'],
              'noUTurnNoLeftTurn',
              'label-turn-rules-sign-img',
            ],

            // Default case, should never happen.
            `label-turn-rules-sign-img`,
          ],
          'icon-size': ['interpolate', ['linear'], ['zoom'], 15, 0.4, 18, 0.6],
          'icon-padding': 0,
          'icon-allow-overlap': false,
        },
      },
      getFirstLabelId(map),
    );
  }
}

type TurnRuleIcons = {
  'no left turn': string;
  'no u-turn no left turn': string;
  'no turns': string;
  'no straight through': string;
  'no right turn': string;
  'no u-turn': string;
  noLeftTurn: string;
  noRightTurn: string;
  noStraightThrough: string;
  noTurns: string;
  noUTurn: string;
  noUTurnNoLeftTurn: string;
};

const turnRuleIcons: TurnRuleIcons = {
  'no left turn': noLeftTurnIcon,
  'no u-turn no left turn': noLeftTurnNoUTurnIcon,
  'no turns': noTurnsIcon,
  'no straight through': noThroughIcon,
  'no right turn': noRightTurnIcon,
  'no u-turn': noUTurnIcon,
  noUTurn: noUTurnIcon,
  noLeftTurn: noLeftTurnIcon,
  noRightTurn: noRightTurnIcon,
  noStraightThrough: noThroughIcon,
  noTurns: noTurnsIcon,
  noUTurnNoLeftTurn: noLeftTurnNoUTurnIcon,
};
